<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区 -->
      <el-row>
        <!-- 增加农贸市场 -->
        <el-col :span="5">
          <el-button
            type="primary"
            class="addbts"
            icon="el-icon-plus"
            @click="addMaket"
          >新增</el-button>
        </el-col>
      </el-row>
      <div class="market-top">
        <div class="market-name">
          <span>市场名称:</span>
          <el-input
            placeholder="请输入农贸市场名称"
            v-model="queryInfo.marketName"
            class="input-with-select"
            clearable
            @change="searchMarket"
            @clear="getMarket"
          >
            <el-button
              slot="append"
              clearable
              @click="searchMarket"
            >搜索</el-button>
          </el-input>
        </div>
        <div class="market-status">
          <span>市场状态：</span>
          <el-select
            v-model="queryInfo.status"
            placeholder="全部"
            clearable
            @change="searchMarket"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 市场列表 -->
      <el-table
        :data="marketData"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column
          prop="id"
          width="100"
          label="id"
        > </el-table-column>
        <el-table-column
          prop="marketName"
          label="市场名称"
        > </el-table-column>
        <el-table-column
          prop="address"
          label="市场地址"
        > </el-table-column>
        <el-table-column
          prop="marketIntro"
          label="市场介绍"
          width="500"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 0">
              <div class="ban-color"></div>
              <div class="start">禁用</div>
            </div>
            <div v-else-if="scope.row.status === 1">
              <div class="start-color"></div>
              <div class="start">启用</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="160"
        >
          <template slot-scope="scope">
            <!-- 状态 0-启用 1-禁用 -->
            <div v-if="scope.row.status === 1">
              <el-button
                size="mini"
                type="primary"
                @click="editMarket(scope.row)"
              >修改</el-button>
              <!-- 禁用 -->
              <el-button
                size="mini"
                type="warning"
                @click="editBan(scope.row)"
              >禁用</el-button>
            </div>
            <div v-else-if="scope.row.status === 0">
              <!-- 启用 -->
              <el-button
                size="mini"
                type="success"
                @click="startMarket(scope.row)"
              >启用</el-button>
              <!-- 删除按钮 -->
              <el-button
                size="mini"
                type="danger"
                @click="deleteMarket(scope.row.id)"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        @size-change="handleSizeChange"
        :current-page="queryInfo.pageNo"
        :page-size="queryInfo.limit"
        :page-sizes="[15, 30, 50, 100]"
        @current-change="handleCurrentChange"
        layout="total,sizes,prev,pager,next,jumper"
      ></el-pagination>
    </el-card>
    <!-- 添加市场的对话框 -->
    <el-dialog
      title="添加市场"
      :visible.sync="addDialogVisible"
      width="730px"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="addFormRef"
        :model="marketForm"
        :rules="addFormRules"
        label-width="150px"
      >
        <el-form-item
          label="市场名称"
          prop="marketName"
        >
          <el-input
            v-model="marketForm.marketName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="添加农贸市场省市县"
          prop="provinceCityAreas"
        >
          <el-cascader
            :props="cascader_props"
            v-model="provinceCityArea"
            @change="getAddChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          class="address"
          label="具体位置"
          ref="addr"
        >
          <MapComp
            ref="MapComp"
            :addressIs="addressIs"
          />
        </el-form-item>
        <el-form-item
          label="市场介绍"
          prop="marketIntro"
        >
          <el-input
            type="textarea"
            :rows="5"
            v-model="marketForm.marketIntro"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getAddMaket"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改市场的对话框 -->
    <el-dialog
      title="修改市场"
      :visible.sync="editDialogVisible"
      width="730px"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="editFormRef"
        :model="editmarketForm"
        :rules="editFormRules"
        label-width="150px"
      >
        <el-form-item
          label="市场名称"
          prop="marketName"
        >
          <el-input
            width="150px"
            v-model="editmarketForm.marketName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="选择农贸市场省市县"
          prop="provinceCityAreas"
        >
          <el-cascader
            :props="cascader_props"
            v-model="provinceCityArea2"
            @change="getEditChange"
            v-if="showCascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="具体位置"
          prop="address"
        >
          <MapComp
            ref="MapComp"
            :addressIs="addressIs"
          />
        </el-form-item>
        <el-form-item
          label="市场介绍"
          prop="marketIntro"
        >
          <el-input
            type="textarea"
            :rows="5"
            v-model="editmarketForm.marketIntro"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getEditMaket"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MapComp from '../market/map'
import { getCity } from '@/api/common'
export default {
  components: {
    MapComp
  },
  data () {
    return {
      showCascader: true,
      queryInfo: {
        limit: 15,
        pageNo: 1,
        marketName: '',
        status: '',
        cityId: '',
        countyId: '',
        provinceId: ''
      },
      total: 0,
      marketData: [
        // locationVo:''
      ], // 市场列表数据
      provinceCityArea: [], // 添加对话框里的省市县
      provinceCityArea2: [], // 修改对话框里的省市县
      // 级联选择器
      cascader_props: {
        value: 'regionId',
        // value: 'name',
        label: 'name',
        lazy: true,
        lazyLoad: this.lazyLoad
      },
      // 省的列表
      provinceList: [],
      // 所有市的列表
      cityList: [],
      // 所有县的列表
      countyList: [],
      // 省市县name的
      provinceCityAreaName: {
        cityName: '',
        countyName: '',
        provinceName: ''
      },
      // 级联和地图联动传入的值
      addressIs: [],
      // 市场状态
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '禁用'
        },
        {
          value: '1',
          label: '启用'
        }
      ],
      MarketStatusValue: '',
      // 控制添加对话框的隐藏与显示
      addDialogVisible: false,
      // 增加菜市场的表单
      marketForm: {
        address: '',
        cityId: '',
        countyId: '',
        provinceId: '',
        lat: '',
        lon: '',
        marketIntro: '',
        marketName: '',
        provinceCityAreas: '' // 校验规则里的省市县
      },
      // 增加菜市场校验规则
      addFormRules: {
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        provinceCityAreas: [
          { required: true, message: '请选择省市县', trigger: 'blur' }
        ],
        marketName: [
          { required: true, message: '请输入市场名字', trigger: 'blur' }
        ],
        marketIntro: [
          { required: true, message: '请输入市场介绍', trigger: 'blur' }
        ]
      },
      // 修改对话框的显示与隐藏
      editDialogVisible: false,
      editmarketForm: {
        address: '',
        cityId: '',
        countyId: '',
        provinceId: '',
        lat: '',
        lon: '',
        marketIntro: '',
        marketName: '',
        provinceCityAreas: [] // 校验规则里的省市县
      },
      editFormRules: {
        // address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        provinceCityAreas: [
          { required: true, message: '请选择省市县', trigger: 'blur' }
        ],
        marketName: [
          { required: true, message: '请输入市场名字', trigger: 'blur' }
        ],
        marketIntro: [
          { required: true, message: '请输入市场介绍', trigger: 'blur' }
        ]
      },
      // 修改菜市场的id
      editId: '',
      edit: '', // 修改市场对话框的row内容
      // 新增对话框的id
      addCityId: ''
    }
  },
  created () {
    this.getMarket()
  },
  mounted () {
    // this.addre()
  },
  // watch: {
  //   addre() {
  //     console.log(this.marketForm.address)
  //     if (this.marketForm.address) {
  //       return this.$refs.addr.clearValidate()
  //     }
  //   }
  // },
  methods: {
    searchMarket () {
      this.queryInfo.pageNo = 1
      this.getMarket()
    },
    // 渲染所有菜市场列表
    async getMarket () {
      const { data: res } = await this.$http.get(
        'agriculturalMarket/marketList',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.marketData = res.data.data
      this.total = res.data.total
      // console.log(this.marketData)
    },
    // 监听 limit 改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getMarket()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getMarket()
    },
    //  得到级联选择器的数据
    getCityData (requestData) {
      return getCity(requestData).then((response) => {
        return response.data.data
      })
    },
    // 省市县数据
    async lazyLoad (node, resolve) {
      const level = node.level
      // 请求参数
      const requestData = {}
      // 省份
      if (level === 0) {
        requestData.level = '1'
        const ret = await this.getCityData(requestData)
        resolve(ret)
        this.provinceList = ret
        // console.log(this.provinceList, '省')
      }
      // 城市
      if (level === 1) {
        requestData.level = '2'
        requestData.parentId = node.value
        requestData.name = node.label
        const ret = await this.getCityData(requestData)
        resolve(ret)
        this.cityList = ret
        // console.log(this.cityList, '市')
      }
      // 区
      if (level === 2) {
        requestData.level = '3'
        requestData.parentId = node.value
        requestData.name = node.label
        const ret = await this.getCityData(requestData)
        const newRet = ret.map((item) => ({
          ...item,
          leaf: true
        }))
        resolve(newRet)
        this.countyList = newRet
        // console.log(this.countyList, '县')
      }
      // console.log(requestData, 33)
    },
    // 增加市场
    addMaket () {
      this.addDialogVisible = true
    },
    // 获取增加菜市场里的级联选择器的省市县ID
    getAddChange (addCityId) {
      this.provinceCityAreaName.provinceName = this.provinceList[
        this.provinceList.findIndex((i) => i.regionId === addCityId[0])
      ].name

      this.provinceCityAreaName.cityName = this.cityList[
        this.cityList.findIndex((i) => i.regionId === addCityId[1])
      ].name
      this.provinceCityAreaName.countyName = this.countyList[
        this.countyList.findIndex((i) => i.regionId === addCityId[2])
      ].name
      this.marketForm.provinceId = addCityId[0]
      this.marketForm.cityId = addCityId[1]
      this.marketForm.countyId = addCityId[2]
      this.addCityId = addCityId
      this.addressIs = [
        this.provinceCityAreaName.provinceName,
        this.provinceCityAreaName.cityName,
        this.provinceCityAreaName.countyName
      ]
      // this.marketForm.address = this.$refs.MapComp.cityName
      // console.log(this.addressIs, 211122)
      // this.$refs.addr.clearValidate()
    },
    // addre() {
    //   console.log(11)
    //   console.log(this.marketForm.address)
    //   if (this.marketForm.address !== '') {
    //     this.$refs.addr.clearValidate()
    //   }
    // },
    getAddMaket () {
      // console.log(this.$refs.MapComp.cityName, 222)
      // console.log(this.$refs.MapComp, '地图参数')
      // 添加里地图的地址
      // this.marketForm.address = this.$refs.MapComp.cityName
      this.marketForm.address = this.$refs.MapComp.cityName
      console.log(this.$refs.MapComp.cityName, 222)
      // 校验规则省市县绑定传值
      this.marketForm.provinceCityAreas = this.addCityId
      console.log(this.marketForm.provinceCityAreas, 222)
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        // 添加里地图的经度
        this.marketForm.lat = this.$refs.MapComp.Lat
        // 添加里地图的纬度
        this.marketForm.lon = this.$refs.MapComp.Lng
        const res = await this.$http.post(
          'agriculturalMarket/',
          this.marketForm
        )
        this.getMarket()
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('增加菜市场成功')
        this.addDialogVisible = false
      })
    },
    // 监听添加对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
      // this.imageUrl.marketLogo = ''
      this.provinceCityArea = []
      this.$refs.MapComp.cityName = ''
      this.$refs.MapComp.Lat = ''
      this.$refs.MapComp.Lng = ''
      this.$refs.MapComp.isShowSearchContent = false
      // this.getMarket()
    },
    // 修改菜市场
    editMarket (edit) {
      // console.log('33333', edit)
      this.editId = edit.id
      // this.imageUrl.marketLogo = edit.marketLogo
      this.editmarketForm.marketName = edit.marketName
      this.editmarketForm.marketIntro = edit.marketIntro
      setTimeout(() => {
        this.$refs.MapComp.cityName = edit.address
        this.$refs.MapComp.Lat = edit.lat
        this.$refs.MapComp.Lng = edit.lon
      }, 100)
      const {
        locationVo: { province, city, county }
      } = edit
      // console.log(province, city, county, 'dadadadada')
      this.editDialogVisible = true
      this.showCascader = false
      this.$nextTick(() => {
        this.showCascader = true
        this.provinceCityArea2 = [
          province.regionId,
          city.regionId,
          county.regionId
        ]
      })
      // console.log(edit)
    },
    // 获取修改对话框里的级联选择器的省市县id
    getEditChange (editCityId) {
      this.provinceCityAreaName.provinceName = this.provinceList[
        this.provinceList.findIndex((i) => i.regionId === editCityId[0])
      ].name
      // console.log(this.provinceCityAreaName.provinceName, '省name')

      // 市name
      this.provinceCityAreaName.cityName = this.cityList[
        this.cityList.findIndex((i) => i.regionId === editCityId[1])
      ].name
      // console.log(this.provinceCityAreaName.cityName, '是name')
      // 县name
      this.provinceCityAreaName.countyName = this.countyList[
        this.countyList.findIndex((i) => i.regionId === editCityId[2])
      ].name
      // console.log(this.provinceCityAreaName.countyName, '县name')
      // 把省市县名字传给地图搜索框
      this.addressIs = [
        this.provinceCityAreaName.provinceName,
        this.provinceCityAreaName.cityName,
        this.provinceCityAreaName.countyName
      ]
      // console.log(this.addressIs, '传给地图2')
    },
    // 点击确定 修改菜市场
    getEditMaket () {
      // 获取省市县回显
      this.editmarketForm.provinceId = this.provinceCityArea2[0]
      this.editmarketForm.cityId = this.provinceCityArea2[1]
      this.editmarketForm.countyId = this.provinceCityArea2[2]
      // console.log(this.provinceCityArea2[0], 99)
      // 拿到校验规则的省市县值
      this.editmarketForm.provinceCityAreas = this.provinceCityArea2
      // console.log(this.editmarketForm.provinceCityAreas, 112233)
      // 修改里地图的地址
      this.editmarketForm.address = this.$refs.MapComp.cityName
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        // 修改里地图的经度
        this.editmarketForm.lat = this.$refs.MapComp.Lat
        // 修改里地图的纬度
        this.editmarketForm.lon = this.$refs.MapComp.Lng
        // this.editmarketForm.marketLogo = this.imageUrl.marketLogo
        const res = await this.$http.put(
          `/agriculturalMarket/${this.editId}`,
          this.editmarketForm
        )
        // console.log(res)
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('修改菜市场成功')
        this.getMarket()
        // this.editmarketForm.provinceCityAreas = []
        this.editDialogVisible = false
      })
    },
    // 监听修改对话框的显示与隐藏
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
      // this.imageUrl.marketLogo = ''
      this.provinceCityArea2 = []
      this.$refs.MapComp.cityName = ''
      this.$refs.MapComp.Lat = ''
      this.$refs.MapComp.Lng = ''
      this.$refs.MapComp.isShowSearchContent = false
      this.editmarketForm.provinceCityAreas = []
    },
    // 删除市场
    async deleteMarket (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除市场，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`agriculturalMarket/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getMarket()
    },
    // 监听头像上传成功的钩子
    // handlesuccess1(response, file, fileList) {
    //   // console.log(response)
    //   this.imageUrl.marketLogo = response.data
    //   // console.log(response.data, 11)
    // },
    // 禁用
    async editBan (row) {
      // 将status设置为0
      row.status = 0
      const { data: res } = await this.$http.put(
        `/agriculturalMarket/${row.id}`,
        row
      )
      if (res.code !== 0) {
        return this.$message.error('禁用失败')
      }
      this.$message.success('已禁用')
      this.getMarket()
    },
    // 启用
    async startMarket (row) {
      row.status = 1
      const { data: res } = await this.$http.put(
        `/agriculturalMarket/${row.id}`,
        row
      )
      if (res.code !== 0) {
        return this.$message.error('启用失败')
      }
      this.$message.success('已启用')
      this.getMarket()
    }
  }
}
</script>

<style lang="less" scoped>
.el-cascader {
  width: 540px;
}
.market-top {
  margin-top: 30px;
  width: 700px;
  height: 50px;
  // border: 1px solid red;
}
.market-name {
  //  margin-top:10px;
  width: 400px;
  .el-input {
    width: 300px;
    margin-left: 80px;
    top: -30px;
  }
}
.market-status {
  width: 200px;
  margin-left: 430px;
  margin-top: -70px;
  .el-select {
    width: 100px;
    margin-left: 10px;
  }
}
.el-link {
  margin-left: 20px;
}
.start-color {
  width: 10px;
  height: 10px;
  margin-top: 10px;
  border: 1px solid #39db2c;
  // color: #39DB2C;
  background: #39db2c;
  border-radius: 50%;
}
.start {
  margin-left: 15px;
  margin-top: -17px;
}
.ban-color {
  width: 10px;
  height: 10px;
  margin-top: 10px;
  border: 1px solid #fa3030;
  // color: #39DB2C;
  background: #fa3030;
  border-radius: 50%;
}
</style>
