<template>
  <div class="area">
    <div id="container"
         class="map"></div>
    <div class="mymap">
      <el-input v-model="cityName"
                class="inputStyle"
                clearable
                @input="getAddress"
                placeholder="请输入省市县地点名" />
      <div class="addName"
           v-show="isShowSearchContent">
        <p v-for="(item,index) in searchContent"
           :key="index"
           @click="getPosition(item)">{{item.title}}</p>
      </div>
    </div>
    <div class="showLat">
      <!-- <el-row>
          <el-col :span="12">
          <el-input v-model="Lat"  placeholder="请输入经度" />
          </el-col>
          <el-col :span="12" class="show-lon">
            <el-input v-model="Lng"  placeholder="请输入纬度" />
          </el-col>
        </el-row> -->

      <!-- <el-input v-model="Lat"
                placeholder="请输入经度">
        <template slot="prepend">经度</template>
      </el-input>
      <el-input v-model="Lng"
                placeholder="请输入纬度">
        <template slot="prepend">纬度</template>
      </el-input> -->
    </div>
  </div>
</template>

<script>
import TMap from 'TMap'
import axios from 'axios'
export default {
  props: {
    addressIs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowSearchContent: true, // 是否显示搜索出来的内容
      cityName: '', // 搜索框v-model绑定的值
      searchContent: [], // 搜索后后台返回的数组
      MapNew: {}, // 复制new TMap.Map的实例给它，它就可以改变中心点。。。
      MarkerLayerNew: {}, // 复制new TMap.MultiMarker实例给它，它就可以添加标注
      Lat: '', // 点击搜索的地点，获取它的经度
      Lng: '', // 点击搜索的地点，获取它的维度
      saveLoaction: {},
      cityValue: '',
      // adcode: {},
      address: [
        // 玄武湖公园
        {
          lat: 32.072163,
          lng: 118.798823
        }
      ]
    }
  },
  watch: {
    addressIs(newValue) {
      console.log(newValue, 222111)
      // this.cityName = newValue
      this.cityValue = newValue[2]
      // console.log(this.cityValue, 333)
      this.getMyAddress(newValue.join(''))
    },
    saveLoaction(newValue) {
      const Item = {
        location: newValue
      }
      this.getPosition(Item)
    }
  },
  methods: {
    // 搜索框里里输入内容，调取腾讯地图webservcie接口
    getAddress(e) {
      axios
        .get('/ws/place/v1/suggestion', {
          params: {
            keyword: e, // 输入的内容
            region: this.cityValue,
            region_fix: 0,
            key: 'OB7BZ-KBJ3F-XB5J4-JIVIS-JKRY2-6VBFD'
          }
        })
        .then((res) => {
          this.isShowSearchContent = false
          this.$nextTick(() => {
            this.searchContent = res.data.data // 赋值给定义的this.searchContent然后进行循环显示
            this.isShowSearchContent = true // 显示搜索内容
          })
        })
    },
    getMyAddress(e) {
      axios
        .get('/ws/place/v1/suggestion', {
          params: {
            keyword: e,
            region_fix: 0,
            region: this.cityValue,
            key: 'OB7BZ-KBJ3F-XB5J4-JIVIS-JKRY2-6VBFD'
          }
        })
        .then((res) => {
          this.isShowSearchContent = false
          this.$nextTick(() => {
            this.saveLoaction = res.data.data[0].location // 赋值给定义的this.searchContent然后进行循环显示
            this.adcode = res.data.data[0].adcode
            // console.log(this.saveLoaction, '地图的第一个定位中心')
            // console.log(this.adcode, '地图第一个省市县id')
            this.isShowSearchContent = true // 显示搜索内容
          })
        })
    },
    // 点击搜索出来的内容获取它的经度纬度
    getPosition(item) {
      // 赋值
      this.$nextTick(() => {
        this.cityName = item.title
      })
      // this.cityName = item.title
      this.Lat = item.location.lat
      this.Lng = item.location.lng
      // 设置中心点，因为this.MapNew = map。所以它和map实例一样有setCenter设置中心点
      // 这个方法就是，你点击搜索的地点，地图的中心变化
      this.MapNew.setCenter(new TMap.LatLng(this.Lat, this.Lng))
      //  将你搜索的内容添加标注。因为setCneter和this.MarkerLayerNew.add（添加标注）这个方法
      // 在渲染dom的时候会产生异步。所以我加了个定时器，以后可以用$nextTick或者promise进行优化
      setTimeout(() => {
        this.MarkerLayerNew.add([
          {
            id: '222',
            styleId: 'myStyle',
            position: new TMap.LatLng(item.location.lat, item.location.lng),
            properties: {
              // 自定义属性
              title: 'marker444'
            }
          }
        ])
      }, 1000)
      console.log(this.cityName, 111)
      // 地图中心点变化后，关闭搜索内容框
      this.isShowSearchContent = false
    },
    initMap() {
      // 定义地图中心点坐标
      const center = new TMap.LatLng(32.055809, 118.793793)
      // console.log(TMap, 333)
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      const map = new TMap.Map(document.getElementById('container'), {
        center: center,
        zoom: 17.2,
        pitch: 43.5,
        rotation: 45
      })
      // 实例赋值，以后就可以用this.MaoNew直接调用方法
      this.MapNew = map
      // console.log(map);
      const markerLayer = new TMap.MultiMarker({
        map
      })
      // 实例赋值，以后直接可以用this.MarkerLayerNew添加标注
      this.MarkerLayerNew = markerLayer
      // 这个this.address就是你后台返回的那个数组，每个地方加一个标注
      this.address.forEach((v) => {
        markerLayer.add([
          {
            id: '3',
            styleId: 'myStyle',
            position: new TMap.LatLng(v.lat, v.lng),
            properties: {
              title: 'marker3'
            }
          }
        ])
      })
    }
  },
  mounted() {
    // 第一步初始化地图
    this.initMap()
  }
}
</script>
<style lang="less" scoped>
// 版心
.area {
  position: relative;
  width: 100%;
  height: 100%;
  // 地图大小
  .map {
    height: 100%;
    width: 100%;
    overflow: hidden;
    // margin-left: 10%;
    margin-top: 11%;
  }
  // 搜索
  .mymap {
    position: absolute;
    top: 0px;
    width: 400px;
    // left: 200px;
    // margin-left: 10%;
    margin-top: -55px;
    z-index: 11111111111111111;
  }
  .inputStyle {
    width: 540px;
  }
  .addName {
    width: 540px;
    // height: 200px;
    color: black;
    background: #ffffff;
    // border: 1px solid #e3e3e3;
    overflow: auto;
    p {
      height: 20px;
      padding-left: 30px;
    }
  }
  .showLat {
    //  position: absolute;
    display: flex;
    // top:10px;
    margin-top: 10px;
    width: 60%;
    // display: flex;
    margin-left: 20%;
    z-index: 11111111111111111;
  }
}
</style>
